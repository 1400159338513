import httpV2 from '@/utils/httpV2'
import get from 'lodash/get'

export async function letterSearch(k, p, wordsnum) {
  if (!k) return []
  let obj = {}
  // wordsnum = 0 时 不传
  if (wordsnum) obj.wordsnum = wordsnum
  const res = await httpV2.get('/stb/v2/search/key', {
    params: {
      k,
      p,
      size: '20',
      ...obj,
    }
  })
  return {
    ...res,
    song: get(res, 'data.song', [])
  }
}